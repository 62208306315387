.form-center {
  text-align: center;
  margin-top: 1em;
  width: 100%;
}
label {
  text-align: left;
  display: block;
  width: 100%;
  padding-left: 5px;
  color: #a3a3a3;
}

.form-full{ width: 80%; margin: auto; }
.label{ color: var(--two-color); }
.sub-label { margin-top: 10px; }
.input-checkboxes{ margin:auto; }

.sign-content {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 1em;
  &.no-pad { padding: 0; }
  color: black;
}

.home-button {
  color: white;
  border: none;
  border-radius: 5px;
  padding: 5px 30px;
  font-size: 1em;
  text-decoration: none;
  margin-top: 10px;
  display: inline-block;
}

.sign-full {
  padding: 20px 0;
  margin:auto;
  width: 350px;
}

.form-box { width: 300px; text-align: center; }
#register-form, .form-left { text-align: left; }
.form-feedback { margin-bottom: 20px; border: solid 1px var(--two-color); padding: 5px; }


.logo-home { width: 90px; }
.form-name { font-size: 1.5em; }

.input-group { margin-bottom: 20px; padding: 0; }
.input-number { width: 90px; margin-left: 10px; }
.input-number-year { width: 130px; }

input, textarea, select { border: 2px solid var(--brand-color); border-radius: 5px; padding: .5em; width: 100%; 
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif; }
input:focus, input:active, textarea:focus, textarea:active, select:focus, select:active   { 
  outline: none; border: solid 2px var(--two-color); border-color: var(--two-color); }
// https://www.w3schools.com/howto/howto_css_placeholder.asp
::placeholder, ::-ms-input-placeholder { color: #C4C5C6; opacity: .5; }

input[type="submit"]:focus, input[type="submit"]:active { border: none; }

.input-require {
  color: red;
  margin: 2px;
  padding: 0;
  max-height: 0;
  overflow: hidden;
  transition: .3s;
} 

.agree-wrap{ margin: 20px 0; }

.select-two {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

input[type="radio"] {
  margin: 10px 0 !important;
  opacity: 50%;
  height: 25px;
}

.radio {
  width: 80px;
  font-size: 1.2em;
  text-align: center;
  margin: 0 50px;
  label{
    text-align: center;
  }
}

.radio-holder { margin: auto; width: 32px; height: 30px;}

.newsub {
  width: 80%;
  display: flex;
  justify-content: center;
  margin: 20px auto;
  font-size: 1.2em;
}


#agree1, .multi-tick {
  width: 100%;
  height: 100%;
  border-radius: 4px;
}
.agree-check { background-color: var(--brand-color); }
.multi-check { background-color: var(--brand-color); } //not working

.multi-select {
  width: 80%;
  display: flex;
  justify-content: left;
  margin: 20px auto;
  font-size: 1.2em;
}
.multi-text { margin: 0; }

#forum-modal {
  color: var(--brand-color);
  margin: 0 10px;
  transition: .4s;
}
#forum-modal:hover {
  color: var(--two-color);
  cursor: pointer;
}

.p-select-two {
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 10px;
  padding: 0 50px;
}

.p-radio {
  width: 120px;
  font-size: 1.2em;
  text-align: center;
  label{
    text-align: center;
  }
  input[type="radio"] {
    margin: 10px 0;
    opacity: 50%;
    height: 25px;
  }
}

// BUTTONS - SEE BUTTON FORM


// RESPONSIVE  ------------------------------------------------------------
@media all and (max-width: 1150px){
  .form-full{ width: 100%; }
} 

@media all and (max-width: 768px){
  .form-full{ width: 80%; }
}

@media all and (max-width: 450px){
  .form-full{ width: 100%; }
  .sign-full { width: 300px; }
  .newsub { width: 100%; margin: 15px auto; }
} 
  
@media all and (max-width: 350px){
  .form-name { font-size: 1.2em; }
  .sign-full { width: 270px; }
}