//Text styles
h2,h3,h4,h5,h6 { font-weight: 500; font-family: Calibri, Candara, Verdana, sans-serif; }

.app-info { margin-bottom: 30px; padding-right: 10px; }
.para { font-size: 1.2em;  }
.sett { font-weight: bold; font-size: 1.5em; }

.warning-text { color: red; }
.welcome-text { text-align: center; font-size: 1.5em; }
.profile-text { color: var(--two-color); text-align: center; width: 100%; }



// RESPONSIVE  ------------------------------------------------------------
/* Desktop styles -------------------------*/
@media all and (min-width: 769px){
} 

/* Mobile styles -------------------------*/
@media all and (max-width: 768px){
  .app-info { margin: 20px; }
}

@media all and (max-width: 350px){
  .app-info { margin: 15px 0; }
} 