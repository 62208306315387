
// MODAL BUTTONS - SEE COMPONENT INSTYLING -------------
// forum buttons - see "forum"

//moderator buttons
.two-buttons { width: 100%; display: flex; margin: 5px 0; }
.mod-button{
  width: 50%; 
  padding: 10px;
  border-radius: 500px;
  text-decoration: none;
  cursor: pointer;
  font-size: 1.1em;
  color: white;
  margin: 0 10px;
  text-align: center;
  user-select: none;
}

.redirect-button {
  color: white;
  border: none;
  border-radius: 5px;
  padding: 7px;
  margin: 20px auto;
  text-decoration: none;
  width: 200px; 
  cursor: pointer;
  font-size: 1.1em;
  text-align: center;
  user-select: none;
}

// ----STYLES-------- 
.primary { background-color: var(--brand-color); }
.action { background-color: var(--action-color) ; }
.disabled { background-color: var(--disabled-color); }
.two-color { background-color: var(--two-color); }
.warning { background-color: red; }

.sets { padding: 0.5em; font-size: 1.6em; }


// -----------------lesson menu buttons ------------------------------------------
.info-btn {
  margin: 5%;
  border: solid 3px var(--two-color);
  font-size: 1.3em;
  height: 90px;
  position: relative;
  cursor: pointer;
}
.title-wrapper { height: 90px; padding: 5%; }
.bibl { padding: 5%; width:100%; }

.info-title {
  color: var(--two-color);
  height: 100%;
  font-weight: bold;
  display: flex; align-items: center; justify-content: left;
}
//side quiz button styles
.qi-link {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  padding: 10px;
}
.qi-img { height: 100%; }
.qi-img-space { padding: 10px; }



// --------- FORM BUTTONS------------------------------------------------------
.submit-btn, input[type="submit"] {
  background-color: var(--action-color);
  color: white;
  border: none;
  border-radius: 5px;
  padding: 5px ;
  font-size: 1.2em;
  margin-top: 5px;
  cursor: pointer;
  width: 100% !important;
  user-select: none;
}

.submit-disabled {
  background-color: var(--disabled-color);
  color: white;
  border: none;
  border-radius: 5px;
  padding: 5px ;
  font-size: 1.2em;
  margin-top: 5px;
  width: 100% !important;
  user-select: none;
}
.sign-a-link, .left-space, a  { color: var(--two-color); cursor: pointer; }

.moreless-parent { position: relative; }
.left-space { position: absolute; 
  transform: translateY(100%); 
  bottom: 0px; right: 30px; }
.space-rm { color: white; }


// RESPONSIVE  ------------------------------------------------------------

/* Desktop styles ---------------------------------------------------*/
@media all and (max-width: 950px){
  .app-info { margin-bottom: 30px; }
}

  /* Mobile styles ---------------------------------------------------*/
  @media all and (max-width: 768px){
  .lb-img { margin-right: 5vw; }
  .qi-img-space { padding: 5px; }
  }

  @media all and (max-width: 550px){
  .info-btn { font-size: 1.2em; height: 80px; }
  .title-wrapper { height: 80px; width: 75%; }
}

  
  @media all and (max-width: 350px){
  .submit-btn, input[type="submit"] { font-size: 1em; }
  .info-btn { font-size: 1em; height: 65px; }
  .title-wrapper { height: 65px; width: 75%; }
  .mod-button{
    padding: 5px;
    font-size: 1em;
    margin: 0 5px;
  }  
}