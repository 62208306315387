// Toast
#toasts {
  position: fixed;
  left: 50%;  
  top: 1em; 
  transform: translateX(-50%); 
  z-index: 9999;
  display: flex;
  justify-content: center;
  flex-direction: column; 
  width: 80%;
}

.toast-entry {  
  background:#FCF3E2;
  margin: 0 auto;
  color: #333;  
  border-radius: 200px;
  box-shadow: 0px 3px 25px rgba(0,0,0,0.14);
  padding: .7em 1.3em;
  font-size: 1em;
  margin-bottom: .25em;
  text-align: center;
  transition: all .2 ease;
  &.is-error {
    background: rgb(247, 9, 9);
    color:#fff;
  }  
}

@media all and (max-width: 550px){
  .toast-entry {  font-size: 1em; 
    padding: .7em 0.9em; }
}