// Base
* { box-sizing: border-box; }

.v-center {  display: flex; align-items: center; justify-content: center; }
.h-center { text-align: center; }

.hidden { display: none;  }
.space-below { height: 1em;}


.clear-fix::after { content: ''; clear: both; display: table; }


// .section { margin: 0; padding: 0; box-sizing: border-box; }


//not used
//.select-disabled { color: var(--disabled-color);}

// root -----------
#root {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;  
  overflow: hidden;
}

body {
  padding: 0;  
  margin: 0;
  color: var(--base-txt-color);
  font-family: var(--base-font-family);
  font-weight: 300;
  background: var(--body-bg);
  height: 100vh;
  font-size: 100%;
  overflow: hidden;
}

//user styles
.confirm-wrap { margin: 50px; font-size: 1.2em; }
.confirm-space { margin-top: 50px; }


// Global class styles ------------------------------------------------------------

// headings ------------
.main-content { padding-top: 70px;height: 100%; }
//70px padding (above) allows for the header 
//which then allows the content panel to fill the remaining screen
.content-panel { 
  height: 100%; width: 100%; 
  overflow-y: auto; 
  padding-right: 10px; 
  padding-bottom: 30px;
}
.content-full { height: 100%; width: 100%; overflow-y: scroll; padding-right: 10px; padding-bottom: 30px; }

// settings page and all pages with paragraph


.parabox { border: solid 1px var(--three-color); margin: 1em; padding: .5em; }

span { font-weight: bold; }
.moreless-parent span { font-weight: normal; }

.divide-line {
  width: 80%;
  margin: 30px auto;
  border-bottom: 1px solid var(--two-color);
}

.e-country {
  min-height: 70px;
  width: 250px;
  margin: 25px auto 10px;
  display: flex; align-items: center; justify-content: center;
}

// RESPONSIVE  ------------------------------------------------------------
/* Desktop styles -------------------------*/
@media all and (min-width: 769px){
.main-content { margin-left: 30vw; margin-right: 20vw; }
} 

/* Mobile styles -------------------------*/
@media all and (max-width: 768px){
.main-content { margin-left: 4vw; margin-right: 0; }
.feedback-widget { display: none; }
}

@media all and (max-width: 450px){
  .e-country {
    min-height: 55px;
    width: 240px;
    margin: 15px auto 10px;
  }
}

@media all and (max-width: 350px){
.parabox { margin: 0.3em; padding: .3em; }
} 