
// Scroll bar styles
// https://www.w3schools.com/howto/howto_css_custom_scrollbar.asp

/* width */
::-webkit-scrollbar { width: 15px; }


/* Track */
::-webkit-scrollbar-track {
  background: var(--bginfo-color);
  border-radius: 500px;
}
 
/* Handle  */
::-webkit-scrollbar-thumb {
  background: var(--brand-color);
  border-radius: 500px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--two-color);
}




