.modal-overlay {
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.4); 
  position: absolute;
  left: 0;
  top: 0;
  z-index: 9999;
  display: flex; align-items: center; justify-content: center;
}
  
.modal-box {
  position: relative;
  background-color: white;
  padding:  7% 5% 5% 5%;
  margin: 5%;
  width: 50%;
  height: 70%;
  display: flex; align-items: center; justify-content: center;
}

.modal-content { text-align: center; }
.hidden { display: none; }

.modal-close {
  position: absolute;
  right: 0;
  cursor: pointer;
  padding: 5%;
  top: 0;
}