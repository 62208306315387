//posts
.forum-thread-title {
  background-color: var(--two-color);
  color: white;
  font-size: 1.4em; 
  padding: 12px 20px 10px 20px;
  text-align: center;
  word-break: break-word;
}

.thread-title-wrap {
  border: 2px solid var(--two-color);
  padding: 8px 10px; 
  margin: 0;
}
.thread-title-name {
  color: var(--three-color); 
  font-size: 1em; 
  font-weight: bold;
  margin-right: 10px;
  display: inline;
}
.thread-title-date {
  color: rgb(132, 130, 130); 
  font-size: 0.9em;
  display: inline;
}

.thread-title-text { margin: 5px 0 0 0; font-size: 1.2em; word-break: break-word; }



// -----------------FORUM BUTTONS ----------------------------------
.forum-button {
  margin: 20px auto;
  color: white;
  border-radius: 500px;
  text-decoration: none;
  padding: 7px 15px;
  display: flex; align-items: center; justify-content: center;
  width: 220px;
  font-size: 1.2em;
  height: 45px;
  user-select: none;
} 

.forum-btn { cursor: pointer; transition: background-color 0.4s ease-out; }
.forum-btn:hover { background-color: var(--two-color); }

.forum-action-btn { transition: .4s; }
.forum-action-btn:hover { background-color: rgb(243, 183, 18); }

.back-img { width: 25px; margin: 0 0 0 20px; }
.img-padding { padding: 3px; }
.frm-larger { font-size: 2.5em; padding-left: 20px; }

// RESPONSIVE  ------------------------------------------------------------
@media all and (max-width: 350px){
  .post-main { width: 95%; display: block; }
  .post-wrap { margin: 10px; display: block; }
  .post-info {
    width: 70%; 
    display: block;
    margin-left: 70px;
    padding: 10px 0 5px 0;
    font-size: 0.8em; 
    text-align: left;
    overflow-wrap: break-word;
    position: relative;
    //background-color: pink;
    border-bottom: solid 2px var(--two-color);
  }
  .forum-button {
    margin: 10px auto;
    font-size: 1.1em;
    height: 35px;
  }

} 