//Select button styles


.priority {
  width: 100%;
  border-bottom: solid 1px var(--two-color);
}

// CUSTOM SELECT -----------------------
.msc-wrap {
  position: relative;
  padding: 0;
}
.msc-list-box {
  position: absolute;
  top: 3px;
  width: 100%;
  z-index: 10;
}

.msc-input {
  cursor: pointer;
  option { display: none; }
}

.msc-ul {
  padding: 10px;
  margin: 0;
  width: 100%;
  box-shadow: 1px 1px 4px rgba(0,0,0,0.4);
  background-color: white;
  max-height: 50vh;
  overflow: auto;
  li {
  list-style-type: none;
  cursor: pointer;
  width: 100%;
  border-bottom: solid .5px rgb(221, 220, 220);
  padding: 5px;
  transition: .1s;
  p {
    margin: 0;
  }
}
  li:hover {
    color: var(--brand-color)
  }
}
.msc-selected { color: var(--two-color) }



 @media all and (max-width: 450px){

 }
