@font-face {
  font-family: abeezee;
  src: url("abeezee-regular.b919076c.ttf");
}

:root {
  --base-txt-color: #222;
  --heading-color: --two-color;
  --base-font-family: "abeezee", Verdana, Segoe UI, "Work Sans", sans-serif;
  --body-bg: #fff;
  --brand-color: #00afc6;
  --link-color: --brand-color;
  --two-color: #028996;
  --three-color: #026164;
  --action-color: #ffbc00;
  --bginfo-color: #fcf3e2;
  --disabled-color: #cdcdcd;
  --peach-group: #ef7043;
  --forrest-group: #00cc0c;
  --azure-group: #1f62ff;
  --app-header-height: 60px;
  --app-header-txt-color: --three-color;
  --app-header-title-font-size: 2em;
}

* {
  box-sizing: border-box;
}

.v-center {
  justify-content: center;
  align-items: center;
  display: flex;
}

.h-center {
  text-align: center;
}

.hidden {
  display: none;
}

.space-below {
  height: 1em;
}

.clear-fix:after {
  content: "";
  clear: both;
  display: table;
}

#root {
  position: absolute;
  inset: 0;
  overflow: hidden;
}

body {
  color: var(--base-txt-color);
  font-family: var(--base-font-family);
  background: var(--body-bg);
  height: 100vh;
  margin: 0;
  padding: 0;
  font-size: 100%;
  font-weight: 300;
  overflow: hidden;
}

.confirm-wrap {
  margin: 50px;
  font-size: 1.2em;
}

.confirm-space {
  margin-top: 50px;
}

.main-content {
  height: 100%;
  padding-top: 70px;
}

.content-panel {
  height: 100%;
  width: 100%;
  padding-bottom: 30px;
  padding-right: 10px;
  overflow-y: auto;
}

.content-full {
  height: 100%;
  width: 100%;
  padding-bottom: 30px;
  padding-right: 10px;
  overflow-y: scroll;
}

.parabox {
  border: solid 1px var(--three-color);
  margin: 1em;
  padding: .5em;
}

span {
  font-weight: bold;
}

.moreless-parent span {
  font-weight: normal;
}

.divide-line {
  width: 80%;
  border-bottom: 1px solid var(--two-color);
  margin: 30px auto;
}

.e-country {
  min-height: 70px;
  width: 250px;
  justify-content: center;
  align-items: center;
  margin: 25px auto 10px;
  display: flex;
}

@media (min-width: 769px) {
  .main-content {
    margin-left: 30vw;
    margin-right: 20vw;
  }
}

@media (max-width: 768px) {
  .main-content {
    margin-left: 4vw;
    margin-right: 0;
  }

  .feedback-widget {
    display: none;
  }
}

@media (max-width: 450px) {
  .e-country {
    min-height: 55px;
    width: 240px;
    margin: 15px auto 10px;
  }
}

@media (max-width: 350px) {
  .parabox {
    margin: .3em;
    padding: .3em;
  }
}

h2, h3, h4, h5, h6 {
  font-family: Calibri, Candara, Verdana, sans-serif;
  font-weight: 500;
}

.app-info {
  margin-bottom: 30px;
  padding-right: 10px;
}

.para {
  font-size: 1.2em;
}

.sett {
  font-size: 1.5em;
  font-weight: bold;
}

.warning-text {
  color: red;
}

.welcome-text {
  text-align: center;
  font-size: 1.5em;
}

.profile-text {
  color: var(--two-color);
  text-align: center;
  width: 100%;
}

@media (max-width: 768px) {
  .app-info {
    margin: 20px;
  }
}

@media (max-width: 350px) {
  .app-info {
    margin: 15px 0;
  }
}

.modal-overlay {
  width: 100%;
  height: 100%;
  z-index: 9999;
  background-color: #0006;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.modal-box {
  width: 50%;
  height: 70%;
  background-color: #fff;
  justify-content: center;
  align-items: center;
  margin: 5%;
  padding: 7% 5% 5%;
  display: flex;
  position: relative;
}

.modal-content {
  text-align: center;
}

.hidden {
  display: none;
}

.modal-close {
  cursor: pointer;
  padding: 5%;
  position: absolute;
  top: 0;
  right: 0;
}

::-webkit-scrollbar {
  width: 15px;
}

::-webkit-scrollbar-track {
  background: var(--bginfo-color);
  border-radius: 500px;
}

::-webkit-scrollbar-thumb {
  background: var(--brand-color);
  border-radius: 500px;
}

::-webkit-scrollbar-thumb:hover {
  background: var(--two-color);
}

.form-center {
  text-align: center;
  width: 100%;
  margin-top: 1em;
}

label {
  text-align: left;
  width: 100%;
  color: #a3a3a3;
  padding-left: 5px;
  display: block;
}

.form-full {
  width: 80%;
  margin: auto;
}

.label {
  color: var(--two-color);
}

.sub-label {
  margin-top: 10px;
}

.input-checkboxes {
  margin: auto;
}

.sign-content {
  color: #000;
  padding: 1em;
  position: absolute;
  inset: 0;
}

.sign-content.no-pad {
  padding: 0;
}

.home-button {
  color: #fff;
  border: none;
  border-radius: 5px;
  margin-top: 10px;
  padding: 5px 30px;
  font-size: 1em;
  text-decoration: none;
  display: inline-block;
}

.sign-full {
  width: 350px;
  margin: auto;
  padding: 20px 0;
}

.form-box {
  width: 300px;
  text-align: center;
}

#register-form, .form-left {
  text-align: left;
}

.form-feedback {
  border: solid 1px var(--two-color);
  margin-bottom: 20px;
  padding: 5px;
}

.logo-home {
  width: 90px;
}

.form-name {
  font-size: 1.5em;
}

.input-group {
  margin-bottom: 20px;
  padding: 0;
}

.input-number {
  width: 90px;
  margin-left: 10px;
}

.input-number-year {
  width: 130px;
}

input, textarea, select {
  border: 2px solid var(--brand-color);
  width: 100%;
  border-radius: 5px;
  padding: .5em;
  font-family: Segoe UI, Tahoma, Geneva, Verdana, sans-serif;
}

input:focus, input:active, textarea:focus, textarea:active, select:focus, select:active {
  border: solid 2px var(--two-color);
  border-color: var(--two-color);
  outline: none;
}

::placeholder, ::-moz-placeholder {
  color: #c4c5c6;
  opacity: .5;
}

input[type="submit"]:focus, input[type="submit"]:active {
  border: none;
}

.input-require {
  color: red;
  max-height: 0;
  margin: 2px;
  padding: 0;
  transition: all .3s;
  overflow: hidden;
}

.agree-wrap {
  margin: 20px 0;
}

.select-two {
  justify-content: center;
  margin-bottom: 20px;
  display: flex;
}

input[type="radio"] {
  opacity: .5;
  height: 25px;
  margin: 10px 0 !important;
}

.radio {
  width: 80px;
  text-align: center;
  margin: 0 50px;
  font-size: 1.2em;
}

.radio label {
  text-align: center;
}

.radio-holder {
  width: 32px;
  height: 30px;
  margin: auto;
}

.newsub {
  width: 80%;
  justify-content: center;
  margin: 20px auto;
  font-size: 1.2em;
  display: flex;
}

#agree1, .multi-tick {
  width: 100%;
  height: 100%;
  border-radius: 4px;
}

.agree-check, .multi-check {
  background-color: var(--brand-color);
}

.multi-select {
  width: 80%;
  justify-content: left;
  margin: 20px auto;
  font-size: 1.2em;
  display: flex;
}

.multi-text {
  margin: 0;
}

#forum-modal {
  color: var(--brand-color);
  margin: 0 10px;
  transition: all .4s;
}

#forum-modal:hover {
  color: var(--two-color);
  cursor: pointer;
}

.p-select-two {
  justify-content: space-evenly;
  margin-bottom: 10px;
  padding: 0 50px;
  display: flex;
}

.p-radio {
  width: 120px;
  text-align: center;
  font-size: 1.2em;
}

.p-radio label {
  text-align: center;
}

.p-radio input[type="radio"] {
  opacity: .5;
  height: 25px;
  margin: 10px 0;
}

@media (max-width: 1150px) {
  .form-full {
    width: 100%;
  }
}

@media (max-width: 768px) {
  .form-full {
    width: 80%;
  }
}

@media (max-width: 450px) {
  .form-full {
    width: 100%;
  }

  .sign-full {
    width: 300px;
  }

  .newsub {
    width: 100%;
    margin: 15px auto;
  }
}

@media (max-width: 350px) {
  .form-name {
    font-size: 1.2em;
  }

  .sign-full {
    width: 270px;
  }
}

.forum-thread-title {
  background-color: var(--two-color);
  color: #fff;
  text-align: center;
  word-break: break-word;
  padding: 12px 20px 10px;
  font-size: 1.4em;
}

.thread-title-wrap {
  border: 2px solid var(--two-color);
  margin: 0;
  padding: 8px 10px;
}

.thread-title-name {
  color: var(--three-color);
  margin-right: 10px;
  font-size: 1em;
  font-weight: bold;
  display: inline;
}

.thread-title-date {
  color: #848282;
  font-size: .9em;
  display: inline;
}

.thread-title-text {
  word-break: break-word;
  margin: 5px 0 0;
  font-size: 1.2em;
}

.forum-button {
  color: #fff;
  width: 220px;
  height: 45px;
  -webkit-user-select: none;
  user-select: none;
  border-radius: 500px;
  justify-content: center;
  align-items: center;
  margin: 20px auto;
  padding: 7px 15px;
  font-size: 1.2em;
  text-decoration: none;
  display: flex;
}

.forum-btn {
  cursor: pointer;
  transition: background-color .4s ease-out;
}

.forum-btn:hover {
  background-color: var(--two-color);
}

.forum-action-btn {
  transition: all .4s;
}

.forum-action-btn:hover {
  background-color: #f3b712;
}

.back-img {
  width: 25px;
  margin: 0 0 0 20px;
}

.img-padding {
  padding: 3px;
}

.frm-larger {
  padding-left: 20px;
  font-size: 2.5em;
}

@media (max-width: 350px) {
  .post-main {
    width: 95%;
    display: block;
  }

  .post-wrap {
    margin: 10px;
    display: block;
  }

  .post-info {
    width: 70%;
    text-align: left;
    overflow-wrap: break-word;
    border-bottom: solid 2px var(--two-color);
    margin-left: 70px;
    padding: 10px 0 5px;
    font-size: .8em;
    display: block;
    position: relative;
  }

  .forum-button {
    height: 35px;
    margin: 10px auto;
    font-size: 1.1em;
  }
}

.two-buttons {
  width: 100%;
  margin: 5px 0;
  display: flex;
}

.mod-button {
  width: 50%;
  cursor: pointer;
  color: #fff;
  text-align: center;
  -webkit-user-select: none;
  user-select: none;
  border-radius: 500px;
  margin: 0 10px;
  padding: 10px;
  font-size: 1.1em;
  text-decoration: none;
}

.redirect-button {
  color: #fff;
  width: 200px;
  cursor: pointer;
  text-align: center;
  -webkit-user-select: none;
  user-select: none;
  border: none;
  border-radius: 5px;
  margin: 20px auto;
  padding: 7px;
  font-size: 1.1em;
  text-decoration: none;
}

.primary {
  background-color: var(--brand-color);
}

.action {
  background-color: var(--action-color);
}

.disabled {
  background-color: var(--disabled-color);
}

.two-color {
  background-color: var(--two-color);
}

.warning {
  background-color: red;
}

.sets {
  padding: .5em;
  font-size: 1.6em;
}

.info-btn {
  border: solid 3px var(--two-color);
  height: 90px;
  cursor: pointer;
  margin: 5%;
  font-size: 1.3em;
  position: relative;
}

.title-wrapper {
  height: 90px;
  padding: 5%;
}

.bibl {
  width: 100%;
  padding: 5%;
}

.info-title {
  color: var(--two-color);
  height: 100%;
  justify-content: left;
  align-items: center;
  font-weight: bold;
  display: flex;
}

.qi-link {
  height: 100%;
  padding: 10px;
  position: absolute;
  top: 0;
  right: 0;
}

.qi-img {
  height: 100%;
}

.qi-img-space {
  padding: 10px;
}

.submit-btn, input[type="submit"] {
  background-color: var(--action-color);
  color: #fff;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  border: none;
  border-radius: 5px;
  margin-top: 5px;
  padding: 5px;
  font-size: 1.2em;
  width: 100% !important;
}

.submit-disabled {
  background-color: var(--disabled-color);
  color: #fff;
  -webkit-user-select: none;
  user-select: none;
  border: none;
  border-radius: 5px;
  margin-top: 5px;
  padding: 5px;
  font-size: 1.2em;
  width: 100% !important;
}

.sign-a-link, .left-space, a {
  color: var(--two-color);
  cursor: pointer;
}

.moreless-parent {
  position: relative;
}

.left-space {
  position: absolute;
  bottom: 0;
  right: 30px;
  transform: translateY(100%);
}

.space-rm {
  color: #fff;
}

@media (max-width: 950px) {
  .app-info {
    margin-bottom: 30px;
  }
}

@media (max-width: 768px) {
  .lb-img {
    margin-right: 5vw;
  }

  .qi-img-space {
    padding: 5px;
  }
}

@media (max-width: 550px) {
  .info-btn {
    height: 80px;
    font-size: 1.2em;
  }

  .title-wrapper {
    height: 80px;
    width: 75%;
  }
}

@media (max-width: 350px) {
  .submit-btn, input[type="submit"] {
    font-size: 1em;
  }

  .info-btn {
    height: 65px;
    font-size: 1em;
  }

  .title-wrapper {
    height: 65px;
    width: 75%;
  }

  .mod-button {
    margin: 0 5px;
    padding: 5px;
    font-size: 1em;
  }
}

.priority {
  width: 100%;
  border-bottom: solid 1px var(--two-color);
}

.msc-wrap {
  padding: 0;
  position: relative;
}

.msc-list-box {
  width: 100%;
  z-index: 10;
  position: absolute;
  top: 3px;
}

.msc-input {
  cursor: pointer;
}

.msc-input option {
  display: none;
}

.msc-ul {
  width: 100%;
  max-height: 50vh;
  background-color: #fff;
  margin: 0;
  padding: 10px;
  overflow: auto;
  box-shadow: 1px 1px 4px #0006;
}

.msc-ul li {
  cursor: pointer;
  width: 100%;
  border-bottom: .5px solid #dddcdc;
  padding: 5px;
  list-style-type: none;
  transition: all .1s;
}

.msc-ul li p {
  margin: 0;
}

.msc-ul li:hover {
  color: var(--brand-color);
}

.msc-selected {
  color: var(--two-color);
}

#toasts {
  z-index: 9999;
  width: 80%;
  flex-direction: column;
  justify-content: center;
  display: flex;
  position: fixed;
  top: 1em;
  left: 50%;
  transform: translateX(-50%);
}

.toast-entry {
  color: #333;
  text-align: center;
  transition: all .2 ease;
  background: #fcf3e2;
  border-radius: 200px;
  margin: 0 auto .25em;
  padding: .7em 1.3em;
  font-size: 1em;
  box-shadow: 0 3px 25px #00000024;
}

.toast-entry.is-error {
  color: #fff;
  background: #f70909;
}

@media (max-width: 550px) {
  .toast-entry {
    padding: .7em .9em;
    font-size: 1em;
  }
}

.forum-thread-title {
  background-color: var(--two-color);
  color: #fff;
  text-align: center;
  word-break: break-word;
  padding: 12px 20px 10px;
  font-size: 1.4em;
}

.thread-title-wrap {
  border: 2px solid var(--two-color);
  margin: 0;
  padding: 8px 10px;
}

.thread-title-name {
  color: var(--three-color);
  margin-right: 10px;
  font-size: 1em;
  font-weight: bold;
  display: inline;
}

.thread-title-date {
  color: #848282;
  font-size: .9em;
  display: inline;
}

.thread-title-text {
  word-break: break-word;
  margin: 5px 0 0;
  font-size: 1.2em;
}

.forum-button {
  color: #fff;
  width: 220px;
  height: 45px;
  -webkit-user-select: none;
  user-select: none;
  border-radius: 500px;
  justify-content: center;
  align-items: center;
  margin: 20px auto;
  padding: 7px 15px;
  font-size: 1.2em;
  text-decoration: none;
  display: flex;
}

.forum-btn {
  cursor: pointer;
  transition: background-color .4s ease-out;
}

.forum-btn:hover {
  background-color: var(--two-color);
}

.forum-action-btn {
  transition: all .4s;
}

.forum-action-btn:hover {
  background-color: #f3b712;
}

.back-img {
  width: 25px;
  margin: 0 0 0 20px;
}

.img-padding {
  padding: 3px;
}

.frm-larger {
  padding-left: 20px;
  font-size: 2.5em;
}

@media (max-width: 350px) {
  .post-main {
    width: 95%;
    display: block;
  }

  .post-wrap {
    margin: 10px;
    display: block;
  }

  .post-info {
    width: 70%;
    text-align: left;
    overflow-wrap: break-word;
    border-bottom: solid 2px var(--two-color);
    margin-left: 70px;
    padding: 10px 0 5px;
    font-size: .8em;
    display: block;
    position: relative;
  }

  .forum-button {
    height: 35px;
    margin: 10px auto;
    font-size: 1.1em;
  }
}

/*# sourceMappingURL=index.a9a85453.css.map */
