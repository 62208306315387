// Vars

:root {
  // base
  --base-txt-color: #222;
  --heading-color: --two-color;
  --base-font-family: 'abeezee', Verdana, Segoe UI, 'Work Sans', sans-serif;
  --body-bg: #fff;
  
  // colors  
  /*
    Primary		#00AFC6
    Secondary 	#028996
    Tertiary (darker) #026164
    Action		#FFBC00
    Background	#FCF3E2
    Disabled	#E8E8E8
  */
  $brand-color: #00AFC6; //primary
  --brand-color: #{$brand-color};
  --link-color: --brand-color;
  $two-color: #028996; //secondary
  --two-color: #{$two-color};
  $three-color: #026164; //tertiary
  --three-color: #{$three-color};

  $action-color: #FFBC00; //action
  --action-color: #{$action-color};
  $bginfo-color: #FCF3E2; //background
  --bginfo-color: #{$bginfo-color};
  $disabled-color: #cdcdcd; // disabled or #C6C6C6
  --disabled-color: #{$disabled-color}; 

  $peach-group: #EF7043; // peach
  --peach-group: #{$peach-group}; 
  $forrest-group: #00CC0C; // forrest
  --forrest-group: #{$forrest-group}; 
  $azure-group: #1F62FF; // azure
  --azure-group: #{$azure-group}; 

  // app header
  --app-header-height: 60px;
  --app-header-txt-color:  --three-color;
  --app-header-title-font-size: 2em; 
  
}